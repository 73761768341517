
import { Component, Prop, Vue } from "vue-property-decorator";

import RecursiveDocuments from "../components/RecursiveDocuments.vue";

type Tile = {
  name: string;
  default_icon: string;
  contentable: Record<string, any>;
};

@Component({
  components: {
    RecursiveDocuments
  }
})
export default class CategoryDocumentsComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;
}
