<template>
  <v-list>
    <v-alert
      v-for="item in items"
      :key="item.id"
      color="primary"
      class="mb-2 pa-0 rounded-pill overflow-hidden"
      text
    >
      <template v-if="item.type.id === 1">
        <v-list-item
          class="primary--text"
          :to="{ name: 'custom-documents', params: { id: item.id } }"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="primary" v-text="'mdi-expand-all'" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-wrap" v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-else-if="item.type.id === 2">
        <v-list-item class="primary--text" :href="item.data.content.url" target="_blank">
          <v-list-item-icon class="mr-4">
            <v-icon color="primary" v-text="documentIcon(item.data.content.extension)" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-wrap" v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-else-if="item.type.id === 3">
        <v-list-item class="primary--text" :href="item.data.content.url" target="_blank">
          <v-list-item-icon class="mr-4">
            <v-icon color="primary" v-text="documentIcon(item.data.content.extension)" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-wrap" v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-alert>
  </v-list>
</template>

<script>
export default {
  name: "RecursiveDocumentsComponent",

  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    documentIcon(extension) {
      let icon = "mdi-file";

      if (extension.match(/jpg|jpeg|png/g) != null) icon = "mdi-image";
      if (extension.match(/link/g) != null) icon = "mdi-link-box";
      if (extension.match(/pdf/g) != null) icon = "mdi-file-pdf-box";

      return icon;
    }
  }
};
</script>
